@import "codemirror/lib/codemirror.css";
@import "codemirror/theme/material-darker.css";
@import "codemirror/theme/pastel-on-dark.css";
@import "codemirror/theme/shadowfox.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer components {
  .rh-btn-secondary {
    @apply center rounded-4px color-[#9d69ff] cursor-pointer flex font-['"Roboto","Helvetica","Arial",sans-serif'] text-[0.8125rem] font-medium justify-center leading-none ml-4 py-4px px-5px uppercase;
  }
} */

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.5s ease;
  background-color: #121212;
}
html {
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

hr {
  color: #3b3b3b;
  display: block;
  height: 1px;
  width: 100%;
}

/* For Webkit browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  width: 6px;
  background-color: #00000000;
}

::-webkit-scrollbar-thumb {
  background-color: #3b3b3b;
  border-radius: 3px;
}

/* For Firefox */
::-moz-scrollbar {
  width: 6px;
  background-color: #00000000;
}

::-moz-scrollbar-thumb {
  background-color: #3b3b3b;
  width: 6px;
}

.backdrop-blur {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); /* For Safari and other WebKit browsers */
}

.font-roboto {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.color-rh {
  color: rgba(255, 255, 255, 0.7);
}

.animate-tooltip {
  animation-name: animateTooltip;
  animation-duration: 0.75s;
  animation-timing-function: ease-in;
  animation-fill-mode: both;
}

@keyframes animateTooltip {
  0% {
    opacity: 0.9;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-12px);
  }
}

/* Add this to your styles.css */
.truncate-middle {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.truncate-middle::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.truncate-middle::after {
  content: attr(data-end);
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
}

.noMarginLeftImportant {
  margin-left: 0rem !important;
}

/* Hover Styles */
.custom-hover-item:hover {
  background-color: #ffffff20;
  color: #fff;
}

.custom-hover-row:hover {
  background-color: #9d69ff20;
  color: #fff;
}

.spinner {
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}
.spinner .circle {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  animation: prixClipFix 2.5s linear infinite;
}

@keyframes rotate {
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    21%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    42%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    64%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    84%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
    88%  {clip-path:polygon(50% 50%,100% 0,100% 0,100% 100%,0 100%,0 0)}
    92%  {clip-path:polygon(50% 50%,100% 100%,100% 100%,100% 100%,0 100%,0 0)}
    96%  {clip-path:polygon(50% 50%,0 100%,0 100%,0 100%,0 100%,0 0)}
    100% {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
}


@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10%, 90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.animation-fade-in-out {
  animation-name: fadeInOut;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

/* Container query styles for TextField */
.container-query-formfield {
  container-type: inline-size;
  width: 100%;
}

.cq-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cq-label {
  width: 100%;
}

.cq-input {
  width: 100%;
}

@container (min-width: 600px) {
  .cq-wrapper {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .cq-wrapper.textarea {
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }

  .cq-label {
    width: auto; 
    width: 100px; 
    padding-right: 10px;
    text-align: right;
    flex-shrink: 0;
  }
  .cq-label.textarea {
    text-align: left; 
    width: 100%;
  }

  .cq-input {
    flex-grow: 1; 
    width: calc(100% - 100px); 
  }
  .cq-base-child {
    padding-left: 100px;
  }
}
